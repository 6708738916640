export default {
  regiolux: {
    showDebugDrawer: "Show Debug Drawer",
    "previous.configuration": "Zurück",
    "next.configuration": "Weiter",
    "reset.configuration": "Auswahl zurücksetzen",
    "reset.subConfiguration": "Gruppe zurücksetzen",
    "bom.header": "Stückliste",
    "bom.sum": "Summe Positionen",
    "add.XAS_GERAETETRAEGER": "weitere Geräteträger hinzufügen",
    "add.XAS_FUNKTIONSMODUL": "Funktionsmodul hinzufügen (Optional)",
    "add.XAS_TRAGSCHIENE": "Tragschiene hinzufügen",
    "add.show_BOM": "Konfigurationsergebnis anzeigen",
    addToShoppingCart: "Zum Warenkorb hinzufügen",
    "sidebar.header": "SRT KONFIGURATOR",
    "dialog.requestQuote.open": "Angebot anfragen",
    "dialog.requestQuote.message": "Nachricht",
    "dialog.requestQuote.email": "Ihre E-Mail-Adresse",
    "dialog.requestQuote.salutation": "Anrede",
    "dialog.requestQuote.firstName": "Vorname",
    "dialog.requestQuote.lastName": "Nachname",
    "dialog.requestQuote.phoneNumber": "Telefonnummer",
    "dialog.requestQuote.optionalDetails": "freiwillige Angaben",
    "dialog.requestQuote.street": "Straße / Nr.",
    "dialog.requestQuote.postcode": "Ort / PLZ",
    "dialog.requestQuote.company": "Firma",
    "dialog.requestQuote.position": "Position",
    "dialog.requestQuote.industry": "Branche",
    "dialog.requestQuote.country": "Land",
    "dialog.requestQuote.mobile": "Mobil",
    "dialog.requestQuote.title": "Warenkorb",
    "dialog.requestQuote.description": "Bitte füllen Sie das Formular aus",
    "dialog.requestQuote.text.cancel": "Zurück",
    "dialog.requestQuote.text.confirm": "Angebot abschicken",
    "dialog.requestQuote.on.confirm": "Angebot abschicken",
    "dialog.requestQuote.dataPrivacy.title": "Datenschutz",
    "dialog.requestQuote.dataPrivacy.text.start":
      "Mit der Bestellung erkläre ich mich mit der Speicherung und Nutzung der Daten gemäß der",
    "dialog.requestQuote.dataPrivacy.text.end":
      "der Firma Regiolux GmbH einverstanden.",
    "dialog.requestQuote.dataPrivacy.link.text": "Datenschutzerklärung",
    "dialog.requestQuote.dataPrivacy.link.url":
      "https://www.regiolux.de/de/datenschutz",
    "dialog.requestQuote.emailMarketingConsent.title": "Information",
    "dialog.requestQuote.emailMarketingConsent.text":
      "Ich bin an regelmäßigen Regiolux Informationen interessiert. Mir ist bewusst, dass mein(e) Daten/Nutzungsverhalten elektronisch gespeichert und zum Zweck der Verbesserung des Kundenservices ausgewertet und verarbeitet werden und, dass ich mich jederzeit abmelden kann. Meine Daten dürfen nicht an Dritte weitergegeben werden.",
  },
};
