import { Exclusive } from "../../types";

import { ReactNode } from "react";

import { Divider, Typography } from "@mui/material";

import "./CustomTitle.css";

type Props = {
  title: string;
} & Exclusive<{ description?: string }, { hasDivider?: boolean }>;

export default function CustomTitle({
  title,
  description,
  hasDivider,
}: Props): ReactNode {
  return (
    <div className="custom-title-container">
      <Typography variant="h6">
        {title}
        {description && <div className="description">{description}</div>}
        {hasDivider && <Divider />}
      </Typography>
    </div>
  );
}
