import { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SubconfigurationVariantNavigationProps } from "@plansoft-configuration-renderer/components";
import {
  useCsAttributes,
  useCsConfigurationApi,
} from "@plansoft-configuration-renderer/framework";

import { useSubstructureStandaloneContentCtx } from "@/renderer/SubstructureStandaloneContentCtx";
import { customPalette } from "@/theme/theme";

import { CustomSubconfigurationResetButton } from "./CustomSubconfigurationButton";
import { CustomSubconfigurationCompleteIndicatorIcon } from "./CustomSubconfigurationCompleteIndicatorIcon";

const Tabs = lazy(() => import("@mui/material/Tabs"));
const Tab = lazy(() => import("@mui/material/Tab"));

/**
 * Tab control on configuration level
 * @category UI
 * @param props
 * @returns
 */

export function CustomSubconfigurationTabsNavigation(
  props: SubconfigurationVariantNavigationProps,
): JSX.Element {
  const {
    items,
    activePath,
    setActivePath: setActiveItem,
    navigationStyle,
  } = props;

  const { selectedSubstructure } = useSubstructureStandaloneContentCtx();

  const { resetCurrentCfgObject } = useCsConfigurationApi();

  const Attributes = useCsAttributes();

  const { t } = useTranslation();

  return (
    <Suspense>
      <Box
        bgcolor={customPalette.primary.light}
        display={"flex"}
        flexDirection={"column"}
        height="100%"
      >
        {selectedSubstructure && (
          <Tab
            key={selectedSubstructure.id}
            className="tab-header"
            label={
              <Typography fontWeight={500}>
                {selectedSubstructure.name}
              </Typography>
            }
          />
        )}
        <Tabs
          variant="scrollable"
          orientation={props.orientation}
          onChange={(_, newValue) => setActiveItem(newValue)}
          value={activePath}
          sx={{
            height: "100%",
            ...navigationStyle,
            position: "inherit",
          }}
        >
          {items.map((item) => {
            const activeAttribute = Attributes.find(
              (attribute) => attribute.path === item.path,
            );
            const selectedAttribute = activeAttribute?.values?.find(
              (value) => value.isSelected,
            );
            return (
              <Tab
                key={item.path}
                value={item.path}
                className={
                  item.type === "ConfigurationAttribute"
                    ? selectedAttribute
                      ? "complete"
                      : ""
                    : item.isComplete
                      ? "complete"
                      : ""
                }
                label={
                  selectedAttribute?.name || selectedAttribute?.id ? (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      textAlign={"left"}
                    >
                      <Typography variant="body2">{item.label}</Typography>
                      <Typography variant="h6" textTransform={"uppercase"}>
                        {selectedAttribute.name ?? selectedAttribute.id}
                      </Typography>
                    </Box>
                  ) : (
                    <>{item.label}</>
                  )
                }
                icon={
                  <CustomSubconfigurationCompleteIndicatorIcon
                    isComplete={
                      item.type === "ConfigurationAttribute"
                        ? selectedAttribute
                          ? true
                          : false
                        : item.isComplete
                    }
                  />
                }
              />
            );
          })}
          {/* Empty tab to create a small gap to ResetButton */}
          <Tab
            label=" " // Empty label to have same styling as other tabs
            icon={
              <CustomSubconfigurationCompleteIndicatorIcon isComplete={false} /> // inComplete icon to have same styling as other tabs
            }
          />
        </Tabs>
        <CustomSubconfigurationResetButton
          onResetClicked={resetCurrentCfgObject}
          label={t("regiolux:reset.configuration")}
        />
      </Box>
    </Suspense>
  );
}
