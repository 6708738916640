import { defaultTheme } from "@4pace-cpq/core";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { Theme, ThemeProvider, createTheme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import { QueryClient } from "@tanstack/react-query";

import { Router } from "./Router";
import { theme as RegioluxTheme } from "./theme/theme";

const cacheNoPrefixer = createCache({
  key: "noprefixer",
  ...(import.meta.env.DEV && { stylisPlugins: [] }),
});

export const queryClient = new QueryClient();
export const cpqPath = "/cpq";

const theme = createTheme(deepmerge(defaultTheme as Theme, RegioluxTheme));

function App(): JSX.Element {
  return (
    <CacheProvider value={cacheNoPrefixer}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router />
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
