import { ReactNode } from "react";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import CustomTitle from "@/components/CustomTitle/CustomTitle";
import { RegioluxCloseIcon } from "@/icons/RegioluxIcons";

type Props = {
  title: string;
  description?: string;
  onClose: () => void;
};

export default function CustomDialogTitle({
  title,
  description,
  onClose,
}: Props): ReactNode {
  return (
    <DialogTitle onClick={(e) => e.stopPropagation()}>
      <CustomTitle title={title} description={description} />
      <IconButton aria-label="close" onClick={onClose}>
        <RegioluxCloseIcon />
      </IconButton>
    </DialogTitle>
  );
}
