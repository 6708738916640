import { useBomContext } from "../BillOfMaterials/BomContext";
import { CustomIndividualComponentMapperProps } from "../IndividualComponentMapper/IndividualComponentMapper";

import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import { useIcon } from "@plansoft-configuration-renderer/components";
import {
  useCsAttribute,
  useSubstructureApi,
  useSubstructureMeta,
} from "@plansoft-configuration-renderer/framework";

import { useSubstructureStandaloneContentCtx } from "@/renderer/SubstructureStandaloneContentCtx";

export function CustomSubstructureAddButton({
  custom,
}: CustomIndividualComponentMapperProps): JSX.Element {
  const { id, configurations } = useCsAttribute();
  const { addSubCfgObject } = useSubstructureApi();
  const { canAdd } = useSubstructureMeta();
  const SubstructureAddIcon = useIcon("SubstructureAddIcon");
  const { t } = useTranslation();

  //helper to track if a new substructure has been added
  const isContentStaleRef = useRef(false);
  const { selectedSubstructure, setSelectedSubstructure } =
    useSubstructureStandaloneContentCtx();
  const { isBomOpen, setIsBomOpen } = useBomContext();

  async function handleClick(): Promise<void> {
    isContentStaleRef.current = true;
    await addSubCfgObject({ path: id });
  }

  useEffect(() => {
    if (isContentStaleRef.current && configurations?.at(-1)) {
      setSelectedSubstructure(configurations?.at(-1) || null);
      setIsBomOpen(false);
      isContentStaleRef.current = false;
    }
  }, [configurations, setSelectedSubstructure, setIsBomOpen]);

  return (
    <Button
      disabled={!canAdd}
      type="button"
      onClick={handleClick}
      sx={{
        justifyContent: "left",
        padding: "0.5rem",
        marginBottom: "0.5rem",
        width: "100%",
      }}
    >
      <img
        src={`/Icons/${custom.icon}.svg`}
        style={{
          width: "3rem",
          paddingRight: isBomOpen || selectedSubstructure ? "0" : "0.5rem",
        }}
      />
      {!selectedSubstructure && !isBomOpen && (
        <>
          <SubstructureAddIcon /> {t(`regiolux:add.${id}`)}
        </>
      )}
    </Button>
  );
}
