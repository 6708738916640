import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { BasicTable } from "@/components/BasicTable/BasicTable";
import { customPalette } from "@/theme/theme";

import { BillOfMaterialsHeader, BillOfMaterialsRow } from "./types";

type BillOfMaterialsProps = {
  headers?: BillOfMaterialsHeader[];
  rows?: BillOfMaterialsRow[];
  totalPrice: string;
  description?: string;
};

export function BillOfMaterials({
  headers,
  rows,
  totalPrice,
  description,
}: BillOfMaterialsProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box
      bgcolor={customPalette.primary.light}
      display="flex"
      flexDirection={"column"}
      padding={"1.25rem"}
    >
      <Typography
        fontSize={"2rem"}
        fontWeight={500}
        lineHeight={"1.4"}
        textTransform={"uppercase"}
      >
        {t("regiolux:bom.header")}
      </Typography>
      {description && (
        <Typography paddingTop={"1rem"}> {description} </Typography>
      )}
      <BasicTable headers={headers} rows={rows} />
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        paddingInline={"1rem"}
      >
        <Typography variant="h6" textTransform={"uppercase"} lineHeight={1.4}>
          {t("regiolux:bom.sum")}
        </Typography>
        <Typography variant="h6" textTransform={"uppercase"} lineHeight={1.4}>
          {totalPrice}
        </Typography>
      </Box>
    </Box>
  );
}
