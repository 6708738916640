import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";

import { language } from "@/Router";
import { RegioluxArrowIcon } from "@/icons/RegioluxIcons";
import { useConfigurationServicePostCustomApiConfigurationPublicShoppingCartConfigurations } from "@/openapi/api-cpq/queries/queries";
import { FloatingLoadingSpinner } from "@/tools/LoadingSpinner";

export function CustomAddToShoppingCartButton(): JSX.Element {
  const { t } = useTranslation();

  const { mutate: addToCart, isPending } =
    useConfigurationServicePostCustomApiConfigurationPublicShoppingCartConfigurations();

  const cfgSessionId = window.sessionStorage.getItem("cfgSessionId");
  const userSessionId = window.sessionStorage.getItem("userSessionId");

  function addToShoppingCart(): void {
    if (userSessionId && cfgSessionId) {
      addToCart(
        {
          requestBody: {
            userSessionId: userSessionId,
            configurationSessionId: cfgSessionId,
            localeID: language ? language : "de-DE",
          },
        },
        {
          onSuccess: (data) => {
            window.location.href = "/cpq/itemGroups/" + data.itemGroupId;
          },
        },
      );
    } else {
      //TODO[sh]: Maybe show error message for user
      console.log("No valid sessionIds found");
    }
  }

  return (
    <>
      {isPending && <FloatingLoadingSpinner />}
      <Button
        disabled={!userSessionId}
        sx={{ marginTop: "1rem" }}
        endIcon={
          <RegioluxArrowIcon
            type="short"
            direction="right"
            style={{ fontSize: "0.8rem" }}
          />
        }
        onClick={addToShoppingCart}
      >
        {t("regiolux:addToShoppingCart")}
      </Button>
    </>
  );
}
