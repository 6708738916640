import { useEffect } from "react";

import Button from "@mui/material/Button";

import { language } from "@/Router";
import { useConfigurationServicePostCustomApiConfigurationPublicShoppingCartShow } from "@/openapi/api-cpq/queries/queries";

export function ShoppingCartButton(): JSX.Element {
  const userSessionId = window.sessionStorage.getItem("userSessionId");
  const itemGroupId = window.sessionStorage.getItem("itemGroupId");

  //TODO[sh]: Before go live - check if all strings are still correct
  const testItemGroupId = "23be78c5-475e-4207-b5b6-2e12f4800ea8";

  const { mutate: showShoppingCart } =
    useConfigurationServicePostCustomApiConfigurationPublicShoppingCartShow();

  useEffect(() => {
    if (userSessionId) {
      showShoppingCart(
        {
          userSessionId: userSessionId,
          localeId: language ? language : "de-DE",
        },
        {
          onSuccess: (data) => {
            const queryString = data.redirectUrl?.split("?")[1];
            const urlParams = new URLSearchParams(queryString);
            const itemGroupId = urlParams.get("itemGroupId");
            if (itemGroupId) {
              window.sessionStorage.setItem("itemGroupId", itemGroupId);
            }
          },
          onError: (error) => {
            console.error("Request failed:", error);
          },
        },
      );
    }
  }, [showShoppingCart, userSessionId]);

  return (
    <Button
      sx={{ marginLeft: "2rem" }}
      href={"/cpq/itemGroups/" + (itemGroupId ? itemGroupId : testItemGroupId)}
    >
      Warenkorb (
      {itemGroupId && itemGroupId !== testItemGroupId ? "CPQ" : "Test"})
    </Button>
  );
}
