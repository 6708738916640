import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { BoxProps } from "@mui/material/Box/Box";

export function LoadingSpinner(): JSX.Element {
  return (
    <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  );
}

export function FloatingLoadingSpinner(props: BoxProps): JSX.Element {
  return (
    <Box
      {...props}
      position="fixed"
      display="flex"
      justifyContent="center"
      alignItems="center"
      right={0}
      bottom={0}
      top={0}
      left={0}
      zIndex={9999}
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <LoadingSpinner />
    </Box>
  );
}
