import { CustomIndividualComponentMapperProps } from "../IndividualComponentMapper/IndividualComponentMapper";

import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import { useIcon } from "@plansoft-configuration-renderer/components";

import { useSubstructureStandaloneContentCtx } from "@/renderer/SubstructureStandaloneContentCtx";

import { useBomContext } from "./BomContext";

export function BomButton({
  custom,
}: CustomIndividualComponentMapperProps): JSX.Element {
  const SubstructureAddIcon = useIcon("SubstructureAddIcon");
  const { t } = useTranslation();

  const { selectedSubstructure, setSelectedSubstructure } =
    useSubstructureStandaloneContentCtx();
  const { isBomOpen, setIsBomOpen } = useBomContext();

  function handleClick(): void {
    setSelectedSubstructure(null);
    setIsBomOpen(true);
  }

  return (
    <Button
      disabled={false}
      type="button"
      onClick={handleClick}
      sx={{
        justifyContent: "left",
        padding: "0.5rem",
        marginBottom: "0.5rem",
        width: "100%",
      }}
    >
      <img
        src={`/Icons/${custom.icon}.svg`}
        style={{
          width: "3rem",
          paddingRight: isBomOpen || selectedSubstructure ? "0" : "0.5rem",
        }}
      />
      {!isBomOpen && !selectedSubstructure && (
        <>
          <SubstructureAddIcon /> {t(`regiolux:add.show_BOM`)}
        </>
      )}
    </Button>
  );
}
