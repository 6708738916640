import { useConfigurationServicePostCustomApiConfigurationPublicStartConfigurator } from "../../openapi/api-cpq/queries/queries";

import { Button } from "@mui/material";

export function TestLoginButton(): JSX.Element {
  const { mutate: startConfigurator } =
    useConfigurationServicePostCustomApiConfigurationPublicStartConfigurator();

  function getUserSessionId(): void {
    startConfigurator(
      {
        requestBody: {
          localeID: "de-DE",
          userToken: "",
          userSessionId: "exampleSessionID",
          cfgObjectID: "CFG_KONFIG_STANDARD",
          externalId: "exampleID",
          callbackUrl: "exampleURL",
          geolocationId: "EU",
        },
      },
      {
        onSuccess: (data) => {
          console.log({ data });
          const queryString = data.redirectUrl?.split("?")[1];
          const urlParams = new URLSearchParams(queryString);
          const userSessionId = urlParams.get("userSessionId");
          const cfgSessionId = urlParams.get("cfgSessionId");

          if (userSessionId) {
            window.sessionStorage.setItem("userSessionId", userSessionId);
          }
          if (cfgSessionId) {
            window.sessionStorage.setItem("cfgSessionId", cfgSessionId);
          }
          window.location.reload();
        },
        onError: (error) => {
          console.error("Request failed:", error);
        },
      },
    );
  }

  return (
    <Button sx={{ marginLeft: "2rem" }} onClick={getUserSessionId}>
      Login (set valid sessionIds)
    </Button>
  );
}
