import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import {
  SubconfigurationNavigationContent,
  SubconfigurationNavigationControl,
  SubconfigurationNavigationHorizontal,
  SubconfigurationNavigationOrientation,
  SubconfigurationNavigationProps,
  SubconfigurationStepperNavigation,
  useSubconfigurationNavigation,
  useSubconfigurationNavigationItems,
} from "@plansoft-configuration-renderer/components";

import {
  CustomSubconfigurationNextButton,
  CustomSubconfigurationPreviousButton,
  CustomSubconfigurationResetButton,
} from "./CustomSubconfigurationButton";
import { CustomSubconfigurationNavigationVertical } from "./CustomSubconfigurationNavigationVertical";
import { CustomSubconfigurationTabsNavigation } from "./CustomSubconfigurationTabsNavigation";

/**
 * Tab control on configuration level
 * @category UI
 * @param props
 * @returns
 */

export function CustomSubconfigurationNavigation(
  props: SubconfigurationNavigationProps,
): JSX.Element {
  const {
    control = SubconfigurationNavigationControl.Stepper,
    orientation = SubconfigurationNavigationOrientation.Horizontal,
  } = props;

  const items = useSubconfigurationNavigationItems({});

  const {
    activePath,
    setActivePath,
    resetActivePath,
    next,
    nextDisabled,
    previous,
    previousDisabled,
  } = useSubconfigurationNavigation(items);

  const Navigation =
    control === SubconfigurationNavigationControl.Stepper
      ? SubconfigurationStepperNavigation
      : CustomSubconfigurationTabsNavigation;

  const AlignmentComponent =
    orientation === SubconfigurationNavigationOrientation.Vertical
      ? CustomSubconfigurationNavigationVertical
      : SubconfigurationNavigationHorizontal;

  const activeItem = items.find((item) => item.path === activePath);

  const { t } = useTranslation();

  return (
    <AlignmentComponent
      navigation={
        <Navigation
          orientation={orientation}
          activePath={activePath}
          setActivePath={setActivePath}
          items={items}
          navigationStyle={props.navigationStyle}
          showCompletenessIndicator={props.showCompletenessIndicator}
          stepperProps={props.stepperProps}
        />
      }
      content={
        activeItem ? (
          <Box display={"flex"} flexDirection={"column"} height="100%" gap={2}>
            <Box overflow="auto">
              <SubconfigurationNavigationContent item={activeItem} />
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              gap={1}
              marginTop="auto"
            >
              <Box display={"flex"} gap={1}>
                <CustomSubconfigurationPreviousButton
                  onPreviousClicked={previous}
                  previousDisabled={previousDisabled}
                />
                <CustomSubconfigurationNextButton
                  onNextClicked={next}
                  nextDisabled={nextDisabled}
                />
              </Box>
              <CustomSubconfigurationResetButton
                onResetClicked={resetActivePath}
                label={t("regiolux:reset.subConfiguration")}
              />
            </Box>
          </Box>
        ) : null
      }
    />
  );
}
