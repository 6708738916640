import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

interface UserOptionalDetailsProps {
  street: string;
  postcode: string;
  company: string;
  position: string;
  industry: string;
  country: string;
  mobile: string;
  setStreet: (street: string) => void;
  setPostcode: (postcode: string) => void;
  setCompany: (company: string) => void;
  setPosition: (position: string) => void;
  setIndustry: (industry: string) => void;
  setCountry: (country: string) => void;
  setMobile: (mobile: string) => void;
}

export default function UserOptionalDetails({
  street,
  postcode,
  company,
  position,
  industry,
  country,
  mobile,
  setStreet,
  setPostcode,
  setCompany,
  setPosition,
  setIndustry,
  setCountry,
  setMobile,
}: UserOptionalDetailsProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box display={"flex"} gap={"1rem"} marginBlock={"1rem"} flexWrap={"wrap"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexBasis={"calc(50% - 0.5rem)"}
      >
        {t("regiolux:dialog.street")}
        <TextField
          value={street}
          onChange={(event) => setStreet(event.target.value)}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexBasis={"calc(50% - 0.5rem)"}
      >
        <Typography>{t("regiolux:dialog.postcode")}</Typography>
        <TextField
          value={postcode}
          onChange={(event) => setPostcode(event.target.value)}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexBasis={"calc(50% - 0.5rem)"}
      >
        <Typography>{t("regiolux:dialog.company")}</Typography>
        <TextField
          value={company}
          onChange={(event) => setCompany(event.target.value)}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexBasis={"calc(50% - 0.5rem)"}
      >
        <Typography>{t("regiolux:dialog.position")}</Typography>
        <TextField
          value={position}
          onChange={(event) => setPosition(event.target.value)}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexBasis={"calc(50% - 0.5rem)"}
      >
        <Typography>{t("regiolux:dialog.industry")}</Typography>
        <TextField
          value={industry}
          onChange={(event) => setIndustry(event.target.value)}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexBasis={"calc(50% - 0.5rem)"}
      >
        <Typography>{t("regiolux:dialog.country")}</Typography>
        <TextField
          value={country}
          onChange={(event) => setCountry(event.target.value)}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexBasis={"calc(50% - 0.5rem)"}
      >
        <Typography>{t("regiolux:dialog.mobile")}</Typography>
        <TextField
          value={mobile}
          onChange={(event) => setMobile(event.target.value)}
        />
      </Box>
    </Box>
  );
}
