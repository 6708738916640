import { BillOfMaterials } from "../BillOfMaterials/BillOfMaterials";
import { BillOfMaterialsMock } from "../BillOfMaterials/BillOfMaterials.mock";
import { useBomContext } from "../BillOfMaterials/BomContext";
import { CustomAddToShoppingCartButton } from "../CustomAddToShoppingCartButton/CustomAddToShoppingCartButton";

import Box from "@mui/material/Box";
import { SubstructureContent } from "@plansoft-configuration-renderer/components";

import { useSubstructureStandaloneContentCtx } from "@/renderer/SubstructureStandaloneContentCtx";

export function StandaloneSubstructureContent(): JSX.Element {
  const { selectedSubstructure } = useSubstructureStandaloneContentCtx();
  const { isBomOpen } = useBomContext();
  return (
    <Box>
      {selectedSubstructure && (
        <SubstructureContent substructure={selectedSubstructure} />
      )}
      {/* TODO {sh}: integrate real data */}
      {/* {isBomOpen && <BillOfMaterialsData />} */}
      {isBomOpen && (
        <Box>
          <BillOfMaterials
            headers={BillOfMaterialsMock.headers}
            rows={BillOfMaterialsMock.rows}
            totalPrice={BillOfMaterialsMock.totalPrice}
          />
          <CustomAddToShoppingCartButton />
        </Box>
      )}
    </Box>
  );
}
