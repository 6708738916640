// generated with @7nohe/openapi-react-query-codegen@1.4.1
import { ConfigurationService } from "../requests/services.gen";
import {
  AddSubConfigurationParams,
  ChangeConfigurationParams,
  ChangeSubConfigurationParams,
  CloneConfigurationParams,
  ConfigurationTableParams,
  CreateConfigurationParams,
  DeleteSubConfigurationParams,
  GetConfigurationParams,
  InvokeUserDefinedFunctionParams,
  InvokeUserDefinedGetterParams,
  LoadConfigurationParams,
  ResetConfigurationAttributesParams,
  ResetConfigurationParams,
} from "../requests/types.gen";

import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

import * as Common from "./common";

export const useConfigurationServiceGetInfo = <
  TData = Common.ConfigurationServiceGetInfoDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetInfoKeyFn(queryKey),
    queryFn: () => ConfigurationService.getInfo() as TData,
    ...options,
  });
export const useConfigurationServiceGetStatistics = <
  TData = Common.ConfigurationServiceGetStatisticsDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetStatisticsKeyFn(queryKey),
    queryFn: () => ConfigurationService.getStatistics() as TData,
    ...options,
  });
export const useConfigurationServiceGetHealth = <
  TData = Common.ConfigurationServiceGetHealthDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetHealthKeyFn(queryKey),
    queryFn: () => ConfigurationService.getHealth() as TData,
    ...options,
  });
export const useConfigurationServiceGetLocales = <
  TData = Common.ConfigurationServiceGetLocalesDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    releaseId,
  }: {
    releaseId?: string;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetLocalesKeyFn(
      { releaseId },
      queryKey,
    ),
    queryFn: () => ConfigurationService.getLocales({ releaseId }) as TData,
    ...options,
  });
export const useConfigurationServiceGetUiLocales = <
  TData = Common.ConfigurationServiceGetUiLocalesDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    releaseId,
  }: {
    releaseId?: string;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetUiLocalesKeyFn(
      { releaseId },
      queryKey,
    ),
    queryFn: () => ConfigurationService.getUiLocales({ releaseId }) as TData,
    ...options,
  });
export const useConfigurationServiceGetUnits = <
  TData = Common.ConfigurationServiceGetUnitsDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    releaseId,
  }: {
    releaseId?: string;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetUnitsKeyFn(
      { releaseId },
      queryKey,
    ),
    queryFn: () => ConfigurationService.getUnits({ releaseId }) as TData,
    ...options,
  });
export const useConfigurationServiceGetCurrencies = <
  TData = Common.ConfigurationServiceGetCurrenciesDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    releaseId,
  }: {
    releaseId?: string;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetCurrenciesKeyFn(
      { releaseId },
      queryKey,
    ),
    queryFn: () => ConfigurationService.getCurrencies({ releaseId }) as TData,
    ...options,
  });
export const useConfigurationServiceGetThemes = <
  TData = Common.ConfigurationServiceGetThemesDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    releaseId,
    themeId,
  }: {
    releaseId?: string;
    themeId?: string;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetThemesKeyFn(
      { releaseId, themeId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getThemes({ releaseId, themeId }) as TData,
    ...options,
  });
export const useConfigurationServiceGetLayouts = <
  TData = Common.ConfigurationServiceGetLayoutsDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    cfgObjectId,
    layoutId,
    releaseId,
    rootCfgObjectId,
  }: {
    cfgObjectId: string;
    layoutId?: string;
    releaseId?: string;
    rootCfgObjectId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetLayoutsKeyFn(
      { cfgObjectId, layoutId, releaseId, rootCfgObjectId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getLayouts({
        cfgObjectId,
        layoutId,
        releaseId,
        rootCfgObjectId,
      }) as TData,
    ...options,
  });
export const useConfigurationServiceGetUiTranslations = <
  TData = Common.ConfigurationServiceGetUiTranslationsDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    contextId,
    localeId,
    releaseId,
  }: {
    contextId: string;
    localeId: string;
    releaseId?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetUiTranslationsKeyFn(
      { contextId, localeId, releaseId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getUiTranslations({
        contextId,
        localeId,
        releaseId,
      }) as TData,
    ...options,
  });
export const useConfigurationServiceGetStructuredTranslations = <
  TData = Common.ConfigurationServiceGetStructuredTranslationsDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    localeId,
    releaseId,
  }: {
    localeId?: string;
    releaseId?: string;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetStructuredTranslationsKeyFn(
      { localeId, releaseId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getStructuredTranslations({
        localeId,
        releaseId,
      }) as TData,
    ...options,
  });
export const useConfigurationServiceGetAsset = <
  TData = Common.ConfigurationServiceGetAssetDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    path,
  }: {
    path: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetAssetKeyFn({ path }, queryKey),
    queryFn: () => ConfigurationService.getAsset({ path }) as TData,
    ...options,
  });
export const useConfigurationServiceGetConfigurators = <
  TData = Common.ConfigurationServiceGetConfiguratorsDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    localeId,
    releaseId,
  }: {
    localeId?: string;
    releaseId?: string;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetConfiguratorsKeyFn(
      { localeId, releaseId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getConfigurators({ localeId, releaseId }) as TData,
    ...options,
  });
export const useConfigurationServiceGetLogs = <
  TData = Common.ConfigurationServiceGetLogsDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    id,
  }: {
    id: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetLogsKeyFn({ id }, queryKey),
    queryFn: () => ConfigurationService.getLogs({ id }) as TData,
    ...options,
  });
export const useConfigurationServiceSaveConfiguration = <
  TData = Common.ConfigurationServiceSaveConfigurationDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    currencyId,
    id,
    includeBom,
    includeSummary,
    localeId,
  }: {
    currencyId?: string;
    id: string;
    includeBom?: boolean;
    includeSummary?: boolean;
    localeId?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceSaveConfigurationKeyFn(
      { currencyId, id, includeBom, includeSummary, localeId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.saveConfiguration({
        currencyId,
        id,
        includeBom,
        includeSummary,
        localeId,
      }) as TData,
    ...options,
  });
export const useConfigurationServiceGetBom = <
  TData = Common.ConfigurationServiceGetBomDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    currencyId,
    id,
    localeId,
  }: {
    currencyId?: string;
    id: string;
    localeId?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetBomKeyFn(
      { currencyId, id, localeId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getBom({ currencyId, id, localeId }) as TData,
    ...options,
  });
export const useConfigurationServiceGetSummary = <
  TData = Common.ConfigurationServiceGetSummaryDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    currencyId,
    id,
    localeId,
  }: {
    currencyId?: string;
    id: string;
    localeId?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetSummaryKeyFn(
      { currencyId, id, localeId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getSummary({ currencyId, id, localeId }) as TData,
    ...options,
  });
export const useConfigurationServiceGetAssembly = <
  TData = Common.ConfigurationServiceGetAssemblyDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    currencyId,
    id,
    localeId,
  }: {
    currencyId?: string;
    id: string;
    localeId?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetAssemblyKeyFn(
      { currencyId, id, localeId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getAssembly({ currencyId, id, localeId }) as TData,
    ...options,
  });
export const useConfigurationServiceGetDrawing = <
  TData = Common.ConfigurationServiceGetDrawingDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    currencyId,
    id,
    localeId,
  }: {
    currencyId?: string;
    id: string;
    localeId?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetDrawingKeyFn(
      { currencyId, id, localeId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getDrawing({ currencyId, id, localeId }) as TData,
    ...options,
  });
export const useConfigurationServiceReload = <
  TData = Common.ConfigurationServiceReloadMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<TData, TError, void, TContext>,
    "mutationFn"
  >,
) =>
  useMutation<TData, TError, void, TContext>({
    mutationFn: () =>
      ConfigurationService.reload() as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceCreateConfiguration = <
  TData = Common.ConfigurationServiceCreateConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        requestBody: CreateConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      requestBody: CreateConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ requestBody }) =>
      ConfigurationService.createConfiguration({
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceGetConfiguration = <
  TData = Common.ConfigurationServiceGetConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: GetConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: GetConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.getConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceCloneConfiguration = <
  TData = Common.ConfigurationServiceCloneConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: CloneConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: CloneConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.cloneConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceKeepConfigurationAlive = <
  TData = Common.ConfigurationServiceKeepConfigurationAliveMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
    },
    TContext
  >({
    mutationFn: ({ id }) =>
      ConfigurationService.keepConfigurationAlive({
        id,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceAddSubConfiguration = <
  TData = Common.ConfigurationServiceAddSubConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: AddSubConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: AddSubConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.addSubConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceCommitConfiguration = <
  TData = Common.ConfigurationServiceCommitConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: GetConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: GetConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.commitConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceRollbackConfiguration = <
  TData = Common.ConfigurationServiceRollbackConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: GetConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: GetConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.rollbackConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceGetTable = <
  TData = Common.ConfigurationServiceGetTableMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: ConfigurationTableParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: ConfigurationTableParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.getTable({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceInvokeUserDefinedFunction = <
  TData = Common.ConfigurationServiceInvokeUserDefinedFunctionMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        name: string;
        requestBody: InvokeUserDefinedFunctionParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      name: string;
      requestBody: InvokeUserDefinedFunctionParams;
    },
    TContext
  >({
    mutationFn: ({ id, name, requestBody }) =>
      ConfigurationService.invokeUserDefinedFunction({
        id,
        name,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceInvokeUserDefinedGetter = <
  TData = Common.ConfigurationServiceInvokeUserDefinedGetterMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        name: string;
        requestBody: InvokeUserDefinedGetterParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      name: string;
      requestBody: InvokeUserDefinedGetterParams;
    },
    TContext
  >({
    mutationFn: ({ id, name, requestBody }) =>
      ConfigurationService.invokeUserDefinedGetter({
        id,
        name,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceLoadConfiguration = <
  TData = Common.ConfigurationServiceLoadConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: LoadConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: LoadConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.loadConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceChangeConfiguration = <
  TData = Common.ConfigurationServiceChangeConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: ChangeConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: ChangeConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.changeConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceResetConfigurationAttributes = <
  TData = Common.ConfigurationServiceResetConfigurationAttributesMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: ResetConfigurationAttributesParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: ResetConfigurationAttributesParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.resetConfigurationAttributes({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceResetConfiguration = <
  TData = Common.ConfigurationServiceResetConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: ResetConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: ResetConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.resetConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceChangeSubConfiguration = <
  TData = Common.ConfigurationServiceChangeSubConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: ChangeSubConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: ChangeSubConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.changeSubConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceDeleteConfiguration = <
  TData = Common.ConfigurationServiceDeleteConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
    },
    TContext
  >({
    mutationFn: ({ id }) =>
      ConfigurationService.deleteConfiguration({
        id,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useConfigurationServiceDeleteSubConfiguration = <
  TData = Common.ConfigurationServiceDeleteSubConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: DeleteSubConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: DeleteSubConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.deleteSubConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
