import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import {
  AttributeControlProps,
  useAttributeLayout,
  useVisibleAttributeValues,
} from "@plansoft-configuration-renderer/components";
import {
  CsValueController,
  useCsAttribute,
  useCsAttributeApi,
  useCsValue,
  useLocalCheckboxValues,
} from "@plansoft-configuration-renderer/framework";

export function CustomCheckbox(props: AttributeControlProps): JSX.Element {
  const values = useVisibleAttributeValues();
  const { fullWidth } = useAttributeLayout();

  return (
    <FormControl component="fieldset" fullWidth={fullWidth}>
      <FormGroup style={{ gap: "0.7rem" }}>
        {values.map((v) => (
          // Inject the value context, could also pass value directly
          <CsValueController value={v} key={v.value}>
            <CheckboxOption>
              <Box width={"100%"}>{props.children}</Box>
            </CheckboxOption>
          </CsValueController>
        ))}
      </FormGroup>
    </FormControl>
  );
}

export function CheckboxOption(props: AttributeControlProps): JSX.Element {
  const value = useCsValue();
  const attribute = useCsAttribute();
  const { toggleOption } = useCsAttributeApi();
  const valueMap = useLocalCheckboxValues();

  const checkboxGroupName = `checkbox-name-${attribute.id}`;
  const checkboxValue = `checkbox-value-${attribute.id}-${value.value}`;
  const checkboxId = `checkbox-id-${attribute.id}-${value.value}`;

  const isDisabled = Boolean(
    !attribute.isAllowed || !value.isAllowed || !value.isActive,
  );
  const isSelected = !!valueMap[value.value];
  const isReadOnly = Boolean(attribute.isReadOnly);

  return (
    <FormControlLabel
      id={checkboxId}
      name={checkboxGroupName}
      value={checkboxValue}
      control={
        <Checkbox
          readOnly={isReadOnly}
          disabled={isDisabled}
          className="custom-checkbox"
        />
      }
      disabled={isDisabled}
      checked={isSelected}
      className={"checkbox" + " " + (isSelected ? "active" : "")}
      onChange={() => toggleOption(value.value, !isSelected)}
      label={<>{props.children}</>}
      disableTypography
    />
  );
}
