import { BillOfMaterials } from "./types";

export const BillOfMaterialsMock: BillOfMaterials = {
  headers: [
    { accessor: "id", label: "" },
    { accessor: "description", label: "Beschreibung" },
    { accessor: "amount", label: "Menge" },
    {
      accessor: "price",
      label: "Brutto/St Listenpreis",
      tooltip: "Price Tooltip",
    },
  ],

  rows: [
    {
      id: 18252580100,
      description: "SDT 1500/II 5x1, 5mm2",
      amount: "1ST",
      price: "55,10 EUR",
    },
    {
      id: 18900231100,
      description: "SDTE, Stirnseite, vw, paar",
      amount: "1ST",
      price: "3,10 EUR",
    },
    {
      id: 18584304100,
      description:
        "SDGOXB/1500 LED 9000 84D ET, vw \nGeräteträger für SDT -  Schnellmontagesystem, Modulllänge /1500",
      amount: "1ST",
      price: "229,50 EUR",
    },
    {
      id: 18837580101,
      description: "SDBK/1500, Bindeabdeckung IP20, vw",
      amount: "1ST",
      price: "5,70 EUR",
    },
    {
      id: 18900034101,
      description: "SDCS, Seilabhängung, me",
      amount: "2ST",
      price: "18,30 EUR",
    },
  ],
  totalPrice: "311,70 EUR",
  description: "Einzelleuchte procube-CUAWF/1500-1",
};
