import { useState } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";

import CustomDialog from "@/components/CustomDialog/CustomDialog";
import CustomDialogActions from "@/components/CustomDialog/CustomDialogActions";
import { RegioluxMessageIcon } from "@/icons/RegioluxIcons";

import { UserInformationForm } from "./UserInformationForm";

export function RequestQuoteDialog(): JSX.Element {
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [salutation, setSalutation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [street, setStreet] = useState("");
  const [postcode, setPostcode] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [industry, setIndustry] = useState("");
  const [country, setCountry] = useState("");
  const [mobile, setMobile] = useState("");
  const [dataPrivacy, setDataPrivacy] = useState(false);
  const [information, setInformation] = useState(false);

  const userInformationProps = {
    message,
    email,
    salutation,
    firstName,
    lastName,
    phoneNumber,
    street,
    postcode,
    company,
    position,
    industry,
    country,
    mobile,
    dataPrivacy,
    information,
    setMessage,
    setEmail,
    setSalutation,
    setFirstName,
    setLastName,
    setPhoneNumber,
    setStreet,
    setPostcode,
    setCompany,
    setPosition,
    setIndustry,
    setCountry,
    setMobile,
    setDataPrivacy,
    setInformation,
  };

  function resetInputFields(): void {
    setMessage("");
    setEmail("");
    setSalutation("");
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setStreet("");
    setPostcode("");
    setCompany("");
    setPosition("");
    setIndustry("");
    setCountry("");
    setMobile("");
    setDataPrivacy(false);
    setInformation(false);
  }

  function onConfirm(): void {
    console.log(
      t("regiolux:dialog.requestQuote.on.confirm") + ": ",
      "\n" + t("regiolux:dialog.requestQuote.email") + ": " + email,
      "\n" + t("regiolux:dialog.requestQuote.salutation") + ": " + salutation,
      "\n" + t("regiolux:dialog.requestQuote.firstName") + ": " + firstName,
      "\n" + t("regiolux:dialog.requestQuote.lastName") + ": " + lastName,
      "\n" + t("regiolux:dialog.requestQuote.phoneNumber") + ": " + phoneNumber,
      "\n_______________________________________",
      "\n" + t("regiolux:dialog.requestQuote.optionalDetails") + ":",
      "\n" + t("regiolux:dialog.requestQuote.street") + ": " + street,
      "\n" + t("regiolux:dialog.requestQuote.postcode") + ": " + postcode,
      "\n" + t("regiolux:dialog.requestQuote.company") + ": " + company,
      "\n" + t("regiolux:dialog.requestQuote.position") + ": " + position,
      "\n" + t("regiolux:dialog.requestQuote.industry") + ": " + industry,
      "\n" + t("regiolux:dialog.requestQuote.country") + ": " + country,
      "\n" + t("regiolux:dialog.requestQuote.mobile") + ": " + mobile,
    );
    resetInputFields();
  }

  return (
    <Box>
      <CustomDialog
        dialog={{
          title: t("regiolux:dialog.requestQuote.title"),
          description: t("regiolux:dialog.requestQuote.description"),
          content: <UserInformationForm {...userInformationProps} />,
          actions: ({ closeDialog }) => (
            <CustomDialogActions
              textCancel={t("regiolux:dialog.requestQuote.text.cancel")}
              textConfirm={t("regiolux:dialog.requestQuote.text.confirm")}
              confirmDisabled={email === "" || !dataPrivacy || !information}
              onCancel={() => {
                closeDialog();
              }}
              onConfirm={() => {
                onConfirm();
                closeDialog();
              }}
            />
          ),
        }}
        buttonProps={{
          children: t("regiolux:dialog.requestQuote.open"),
          startIcon: <RegioluxMessageIcon />,
        }}
      />
    </Box>
  );
}
